<template>
  <UiPopup
    :model-value="modelValue"
    title="Release to pool"
    description="Please indicate the reason. Leads will be removed to Pool."
    :primary-button-text="retrieving ? '' : 'Release to pool'"
    :secondary-button-text="retrieving ? '' : 'Close'"
    size="small"
    :loading="loading || (Array.isArray(checkedIds) && !checkedIds?.length)"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <div id="release-pool-popup" class="mt-4 transition-all duration-200">
      <UiLoader v-if="retrieving" />
      <form v-else ref="form" @submit.prevent>
        <UiInputSelect
          v-model="data.pipeline_step_id"
          :items="reasonsItems"
          name="Reason"
          placeholder="Select reason"
          class="mb-4"
          :error="useGetFieldErrors(v$, ['pipeline_step_id'])"
        />
        <UiInputSelect
          v-if="isLanguageBarrierReason"
          v-model="data.language_id"
          :items="languages"
          name="language"
          placeholder="Add language (optional)"
          class="mb-4"
        />
        <UiInputTextField
          v-model="data.reason"
          name="Other"
          show-limit
          :max-length="50"
          placeholder="Or add other reason"
          class="mb-4 !flex-col-reverse gap-1"
          error-wrapper-class="!bottom-0"
          :error="useGetFieldErrors(v$, ['reason'])"
        />
        <div v-if="checkedIds" class="mt-6 flex flex-col gap-2 rounded-lg bg-secondary-10 p-3 text-left">
          <p class="text-subhead-4 text-additional-3-100">
            Oops! Some leads can’t be Released to Pool ({{ selectedIds.length - checkedIds.length }}).
          </p>
          <small class="mb-2">Some of the selected leads are ‘Closed’ or “Already in Pool”.</small>
          <small
            >They cannot be released to Pool and have been removed from the selection. You can
            <span class="text-subhead-4 cursor-pointer underline" @click="goToNotValidLeads"
              >open these leads in new tab.</span
            >
          </small>
        </div>
      </form>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import omitBy from 'lodash/omitBy'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, requiredIf } from '@vuelidate/validators'
import { PIPELINES, POOL_REASONS, POOL_STAGES } from '@/constants'
import type { Lead, Stage, LibraryItem, InputItem } from '@/types'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  selectedLeads: Lead[]
  showToast?: boolean
}

const retrieving = ref(true)
const poolVerifiedStage = ref()

const getPoolPipeline = async () => {
  try {
    const pool = await useGetPipelineByCode(PIPELINES.POOL)
    poolVerifiedStage.value = pool.stages?.find((s: Stage) => s.code === POOL_STAGES.VERIFIED)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  showToast: false,
})

const loading = ref(false)
const data = ref({
  pipeline_step_id: null,
  language_id: null,
  reason: '',
})

const selectedIds = ref<number[]>(props.selectedLeads?.map((l: Lead) => l.id) || [])
const checkedIds = ref<number[] | undefined>(undefined)
const languages = ref<InputItem[]>([])

const reasonsItems = computed(() =>
  poolVerifiedStage.value.steps
    ?.filter((s: LibraryItem) => s.code !== POOL_REASONS.USER_DELETION)
    .map((s: LibraryItem) => ({ value: s.id, text: s.name, code: s.code }))
)

const isLanguageBarrierReason = computed(() => {
  return (
    data.value.pipeline_step_id &&
    reasonsItems.value?.find(
      (s: InputItem) => s.value === data.value.pipeline_step_id && s.code === POOL_REASONS.LANGUAGE_BARRIER
    )
  )
})

const isOtherReason = computed(() => {
  return Boolean(
    data.value.pipeline_step_id &&
      reasonsItems.value?.find(
        (s: InputItem) => s.value === data.value.pipeline_step_id && s.code === POOL_REASONS.OTHER
      )
  )
})

const rules = computed(() => ({
  pipeline_step_id: { required: helpers.withMessage('The reason is required', required) },
  reason: { required: helpers.withMessage('The note is required', requiredIf(isOtherReason.value)) },
}))

const v$ = useVuelidate(rules, data)

onNuxtReady(async () => {
  retrieving.value = true
  await Promise.all([getPoolPipeline(), getLanguages(), validateSelectedLeads()])
  retrieving.value = false
})

const validateSelectedLeads = async () => {
  try {
    const data = await useCheckBulkAction('release-to-pool', selectedIds.value)
    if (data.length !== selectedIds.value.length) {
      checkedIds.value = data
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getLanguages = async () => {
  try {
    const languagesList = await useLanguages()
    languages.value = languagesList.map((l: LibraryItem) => ({ text: l.name, value: l.id }))
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const findLanguageName = (id: number) => {
  return languages.value.find((l: InputItem) => l.value === id)?.text
}

const goToNotValidLeads = () => {
  const notValidLeads = selectedIds.value.filter((leadId: number) => !checkedIds.value?.includes(leadId))
  const params = notValidLeads.map((l: number) => `lead_ids=${l}`).join('&')
  const link = notValidLeads.length > 1 ? `${window.location.pathname}?${params}` : `/leads/${notValidLeads[0]}`
  navigateTo(link, {
    open: {
      target: '_blank',
    },
  })
}

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  loading.value = true
  const cleanData = omitBy(toRaw(data.value), (f) => f === '' || f === undefined)
  const payload = {
    ...cleanData,
  }
  delete payload.language_id

  let movedToRotation = false

  try {
    if (checkedIds.value?.length) {
      selectedIds.value = selectedIds.value.filter((leadId: number) => checkedIds.value?.includes(leadId))
    }
    if (isLanguageBarrierReason.value && data.value.language_id) {
      const { success } = await useBulkMoveLeadToRotation(data.value.language_id, selectedIds.value)
      if (success) {
        movedToRotation = true
      } else {
        payload.reason = findLanguageName(data.value.language_id)
      }
    }

    if (!movedToRotation) {
      payload.lead_ids = selectedIds.value
      await useBulkMoveLeadToPool(payload)

      if (props.showToast) {
        const toastData = {
          lead_ids: payload.lead_ids,
          loading: true,
        }
        uiStore.showToast('release_to_pool', { data: toastData }, false)
      }
    }

    emits('input')
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
